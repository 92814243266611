<template>
  <div class="treechoose">
    <div class="menghe">新北区</div>
    <div class="tree">
      <div class="tree-list" v-for="item in zhenlist"
           :key="item.areaId"
           @click="choose=item.areaName"
           :class="{active:choose===item.areaName}"
      >{{item.areaName}}</div>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "treechoose",
  data() {
    return {
      choose:'',
    }
  },
  watch:{
    choose(){
      this.$store.commit('setchoosecameraarea',this.choose)
    }
  },
  computed:{
    ...mapState({
      zhenlist: state =>state.zhenlist
    }),
  },
  mounted() {
    this.$store.commit('setchoosecameraarea',this.choose)
  }
};
</script>

<style scoped lang="scss">
.treechoose{
  margin: 50px 50px 0 70px;
  .menghe{
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #08F5F8;
    width: 100%;
    text-align: left;
  }
  .tree{
    //border-left: #FFFFFF 2px solid;
    min-height: 50px;
    //background-color: red;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &::before{
      content: "";
      position: absolute;
      display: block;
      width: 1px;
      height: calc(100% - 32px);
      top: 11px;
      background-color: #ffffff;
    }
    .tree-list{
      width: 100%;
      height: 45px;
      text-align: left;
      margin-left: 22px;
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &.active{
        color: #08F5F8;
      }
      &::before{
        content: "";
        position: absolute;
        display: block;
        width: 18px;
        height: 1px;
        top: 50%;
        left: -20px;
        background-color: #ffffff;
      }
    }
  }
}

</style>
