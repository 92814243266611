<template>
  <my-dv-box width="1920px" height="1080px">
    <my-dv-box class="camera" xAlign="center" top="100px" :position="true"  width="100%" height="896px"  >
      <my-dv-box class="left" top="9px" left="23px" width="260px" height="850px" v-if="getcid===0">
        <my-dv-box top="46px" width="100%" x-align="center" class="camera-title">所有区域</my-dv-box>
        <my-dv-box top="85px" width="100%" padding="0 2px" height="700px" x-align="center" class="camera-content">
                    <my-dv-box x-align="center" width="190px">
                      <el-tree :data="getlist"
                               default-expand-all
                               :indent="15"
                               @node-click="sadasd"
                      ></el-tree>
                    </my-dv-box>
<!--          <treechoose></treechoose>-->
        </my-dv-box>
      </my-dv-box>
      <my-dv-box class="right" :width="getcid===0?'1580px':'1800px'" right="40px" top="27px" height="812px">
        <router-view></router-view>
      </my-dv-box>
    </my-dv-box>
    <Tabbar></Tabbar>
  </my-dv-box>

</template>

<script>
import MyDvBox from "@xdh/my/ui/lib/dv/packages/my-dv-box";
import MyDvText from "@xdh/my/ui/lib/dv/packages/my-dv-text";
import Header from "./../Header";
import {cameraInfo, getMenuByFarmId} from "@/utils/api";
import Treechoose from "@/views/camera/treechoose";
import Tabbar from "@/views/Tabbar";
import {mapState} from "vuex";
export default {
  name: "index",
  components:{Tabbar, Treechoose, MyDvBox,MyDvText,Header},
  data(){
    return {
      list:[],
      tree:[],
      cid:0,
    }
  },
  mounted() {
    if (this.$route.query?.id){
      this.id=this.$route.query.id
    }
  },
  computed:{
    getlist(){
      let list=[]
      this.zhenlist?.map(i=>{
        let s={label:i.areaName,areaId:i.areaId,level:2}
        s.children=[]
        this.cameraArea?.map(ii=>{
          if (ii.areaName===s.label){
            s.children.push({label:ii.farmName,areaName:i.areaName,areaId:i.areaId,farmId:ii.farmId,level:3})
          }
        })
        list.push(s)
      })
      return [{label:'新北区',level:1,children:list}];
    },
    ...mapState({
      cameraArea: state =>state.cameraArea,
      zhenlist: state =>state.zhenlist
    }),

    getcid(){
      return this.$route.query&&this.$route.query.cid?parseInt(this.$route.query.cid):0
    },
  },
  methods: {
    showMsg() {

    },
    sadasd(tree, node) {
        if (node.data.level===2){
          this.$store.commit('setchoosecameraarea',node.data.label)
          this.$store.commit('setchoosecamerafarm','')
        }else if (node.data.level===3){
          this.$store.commit('setchoosecameraarea',node.data.areaName)
          this.$store.commit('setchoosecamerafarm',node.data.label)
        }else{
          this.$store.commit('setchoosecameraarea','')
          this.$store.commit('setchoosecamerafarm','')
        }
    },
  }
};
</script>

<style scoped lang="scss">
.camera{
  .right{

  }
  .left{
    background: url("https://xinbeimenghe.oss-cn-shanghai.aliyuncs.com/images/组2455.png") no-repeat;
    background-size: 100% 100%;
    .camera-title{
      font-size: 31px;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
    }
    .camera-content{
      text-align: center;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
      overflow: auto;
      //>div{
      //  cursor: pointer;
      //  position: relative;
      //  margin-top: 21px;
      //  overflow: hidden;
      //  white-space: nowrap;
      //  text-overflow: ellipsis;
      //  padding: 0 20px;
      //}
      //.active{
      //  overflow-y: auto;
      //  color: #08F5F8;
      //  font-weight: 400;
      //}
    }
  }

  ::v-deep{
    .el-tree-node__expand-icon:before{
      content: "\e6d9";
    }
    .el-tree-node__expand-icon.expanded{
      transform: none;
    }
    .expanded:before{
      content: "\e6d8";
    }
    .el-tree-node__expand-icon{
      &:not(.is-leaf) {
        font-size: 12px;
        font-weight: bold;
        color: #FFFFFF;
      }
    }
    .el-tree-node:focus>.el-tree-node__content{
      background-color: rgba(0,0,0,0);
    }
    .el-tree-node{
      .el-tree-node__content{
        margin: 2px 0;
        &:hover{
          background-color: rgba(0,0,0,0);
        }
      }
    }
    .el-tree-node__label{
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      max-width: 150px;
      overflow: hidden;
      white-space:nowrap;
      display: inline-block;
      text-overflow:ellipsis;
      &:hover{
        display:block;
        text-overflow: unset;
      }
    }
    .is-current{
      .el-tree-node__label{
        color: #08F3F7;
      }
    }
  }
}
</style>
